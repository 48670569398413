import { AddPhotoAlternate, DeleteForever } from '@repo/mui/icons';
import { LoadingButton } from '@repo/mui/LoadingButton';
import { Box } from '@repo/mui/Box';
import { CircularProgress } from '@repo/mui/CircularProgress';
import { IconButton } from '@repo/mui/IconButton';
import { Tooltip } from '@repo/mui/Tooltip';
import { Typography } from '@repo/mui/Typography';
import { Alert } from '@repo/mui/Alert';
import { blue, green, grey, yellow } from '@repo/mui/colors';
import axios, { AxiosError } from 'axios';
import imageCompression from 'browser-image-compression';
import { LegacyRef, useCallback, useRef, useState } from 'react';
import { UploadItem } from './ImageUploadList/data';

function getUniqueFileNames(prevFileNames: string[], files: FileList): string[] {
  const uniqueFileNamesSet = new Set(prevFileNames);
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    if (file) {
      uniqueFileNamesSet.add(file.name);
    }
  }
  return Array.from(uniqueFileNamesSet);
}

type FilesToUploadMap = Record<string, UploadItem>;
type Props = {
  id: string;
  onUploadDone: (urls: string[]) => void;
};

export default function UploadArea({ id, onUploadDone }: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [filesToBeUploaded, setFilesToBeUploaded] = useState<FilesToUploadMap>(
    {} as FilesToUploadMap,
  );
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [compressProgress, setCompressProgress] = useState<Record<string, number>>({});
  const [isCompressInProgress, setIsCompressInProgress] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const [uploadError, setUploadError] = useState('');

  const handleUploadError = useCallback(
    (
      error: AxiosError<{
        details: { errorID: string; publicErrorMessage: string };
      }>,
      fileName: string,
    ) => {
      console.error(`Error uploading ${fileName}:`, error);
      let errorMessage = 'Something went wrong.';
      if (error.response?.data?.details) {
        const { errorID, publicErrorMessage } = error.response.data.details;
        errorMessage = `${publicErrorMessage} - ErrorID: ${errorID}`;
      }
      setUploadError(errorMessage);
    },
    [],
  );

  const uploadImage = async (uploadItem: UploadItem) => {
    try {
      const headers = {
        'Content-type': 'application/json; charset=UTF-8',
      };
      const res = await axios.post(`https://roger-api.herokuapp.com/api/v1/image`, uploadItem, {
        headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress((prev) => ({
            ...prev,
            [uploadItem.name]: progress,
          }));
        },
      });
      return res;
    } catch (err) {
      if (err instanceof AxiosError) handleUploadError(err, uploadItem.name);
      return undefined;
    }
  };

  const handleCompress = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const incomingFiles = e.target.files;
    if (!incomingFiles) {
      console.log('Error: no files');
      return;
    }

    const names = getUniqueFileNames(fileNames, incomingFiles);
    // first set all file names to show
    setFileNames(names);
    // then compress the ones that hasn't been processed before
    handleImageCompression(names, incomingFiles);
  };

  const handleSingleUpload = async (file: UploadItem) => {
    try {
      // clear cache
      setUploadProgress({});
      // upload
      const response = await uploadImage(file);
      // save in cache
      if (response?.data?.url) {
        onUploadDone([response.data.url]);
      } else {
        console.warn(`Image "${file.name}" was not uploaded. "response.data.url is empty."`);
      }
      removeFromList(file.name);
    } catch (err) {
      const e = err as AxiosError;
      let UIMessage =
        'Please, refresh your browser and try again. See console logs for detailed error.';
      const errorData = e?.response?.data as {
        details: { errorID: string; publicErrorMessage: string };
      };
      if (errorData?.details) {
        const { errorID, publicErrorMessage } = errorData.details;
        UIMessage = `${publicErrorMessage} - ErrorID: ${errorID}`;
      }
      setUploadError(`Something went wrong.` + UIMessage);
    }
  };

  async function handleImageCompression(fileNamesToCompress: string[], fileList: FileList) {
    try {
      setIsCompressInProgress(true);
      const compressionPromises = Array.from(fileList)
        .filter((file) => file && !compressProgress[file.name])
        .map(compressImage);
      await Promise.all(compressionPromises);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsCompressInProgress(false);
    }
  }

  const compressImage = useCallback(async (image: File) => {
    const compressionSettings = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
      initialQuality: 0.2, // quality to compress to - between 0 and 1
      onProgress: (percent: number) => {
        setCompressProgress((prev) => ({ ...prev, [image.name]: percent }));
      },
    };
    try {
      const compressedFile = await imageCompression(image, compressionSettings);
      if (!compressedFile) throw new Error('compressedFile is undefined');
      const compressedBase64 = await imageCompression.getDataUrlFromFile(compressedFile);

      const newItem: UploadItem = {
        type: compressedFile.type,
        name: compressedFile.name,
        base64: compressedBase64,
        size: `${Number((compressedFile.size ?? 0) / 1000000).toFixed(2)} MB`,
      };

      // upload the image
      setUploadInProgress(true);
      await handleSingleUpload(newItem);
      setUploadInProgress(false);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  function removeFromList(fileName: string) {
    //remove from file name list
    setFileNames((prev) => {
      return prev.filter((n) => n !== fileName);
    });
    // remove from files to upload list
    setFilesToBeUploaded((prev) => {
      const newUploadList = { ...prev };
      delete newUploadList[fileName];
      return newUploadList;
    });
    // remove from compress progress as compress process looks at there to not compress duplicate files
    setCompressProgress((prev) => {
      const { [fileName]: _, ...newCompressProgress } = prev;
      return newCompressProgress;
    });
  }
  return (
    <Box
      sx={{
        background: grey[200],
        borderRadius: '4px',
        boxShadow: `inset 0 0 0 1px ${grey[300]}`,
        padding: '16px 4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
          }}>
          <label htmlFor={id} style={{ marginBottom: 2 }}>
            <LoadingButton
              size='small'
              loading={isCompressInProgress}
              disabled={uploadInProgress}
              loadingPosition='start'
              startIcon={<AddPhotoAlternate />}
              onClick={() => inputRef.current?.click()}
              variant='outlined'
              sx={{
                borderRadius: '4px',
                height: '28px',
                fontSize: '0.75rem',
                textTransform: 'none',
                width: '170px',
              }}>
              Choose to Upload
            </LoadingButton>
            <input
              style={{
                position: 'absolute',
                visibility: 'hidden',
                width: 0,
                height: 0,
                overflow: 'hidden',
              }} // this is a hidden element to control file upload
              ref={inputRef as unknown as LegacyRef<HTMLInputElement>}
              accept='image/png, image/jpeg'
              id={id}
              multiple
              type='file'
              onChange={handleCompress}
            />
          </label>
        </Box>
        <Typography
          variant='caption'
          style={{ lineHeight: '0.8rem', color: grey[700], marginTop: 4 }}>
          30MB file limit. Only accepts jpeg and png formats.
        </Typography>
      </Box>
      {uploadError && <Alert severity='error'>Upload failed: {uploadError}</Alert>}
      {fileNames.length > 0
        ? fileNames.map((name, i) => {
            const fileSize = filesToBeUploaded?.[name]?.size;
            return (
              <FileUploadProgress
                key={name}
                compressProgress={compressProgress}
                fileSize={fileSize}
                i={i}
                name={name}
                uploadInProgress={uploadInProgress}
                removeFromList={removeFromList}
                uploadProgress={uploadProgress}
              />
            );
          })
        : null}
    </Box>
  );
}

const FileUploadProgress = ({
  compressProgress,
  fileSize,
  i,
  name,
  uploadInProgress,
  removeFromList,
  uploadProgress,
}: {
  compressProgress: any;
  fileSize: any;
  i: any;
  name: any;
  uploadInProgress: any;
  removeFromList: any;
  uploadProgress: any;
}) => {
  return (
    <div key={name} style={{ display: 'flex', marginTop: i ? 1 : 4 }}>
      <Typography variant='body2' sx={{ height: '1rem', fontSize: '0.8rem' }}>
        <i
          title={name}
          style={{
            maxWidth: 180,
            verticalAlign: 'middle',
            overflow: 'hidden',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}>
          {name}
        </i>
        {fileSize ? ` (${fileSize}) ` : ''}
        {' - '}
        {compressProgress[name]! < 100 && compressProgress[name]! > 0 && (
          <CircularProgress
            style={{
              width: 12,
              height: 12,
            }}
          />
        )}
        {!compressProgress[name] && <span style={{ color: yellow[800] }}>Awaiting...</span>}
        {compressProgress[name]! > 0 &&
          compressProgress[name]! < 100 &&
          `${compressProgress[name]}%`}
        {compressProgress[name] === 100 && !uploadInProgress && (
          <>
            <span style={{ color: yellow[800] }}>Ready</span>
            <RemoveButtonTooltip removeFromList={removeFromList} />
          </>
        )}
        {uploadInProgress && <UploadProgress uploadProgress={uploadProgress} name={name} />}
      </Typography>
    </div>
  );
};

const RemoveButtonTooltip = ({ removeFromList }: { removeFromList: any }) => {
  return (
    <Tooltip title={'Remove'} placement='right'>
      <IconButton onClick={() => removeFromList(name)} sx={{ ml: 0.2, p: '1px !important' }}>
        <DeleteForever
          style={{
            width: 20,
            height: 20,
            fontWeight: 'bold',
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

const UploadProgress = ({ uploadProgress, name }: { uploadProgress: any; name: any }) => {
  return (
    <span
      style={{
        color: uploadProgress?.[name]! < 100 ? blue[600] : green[500],
      }}>
      {uploadProgress[name]! < 100 ? `Upload:${uploadProgress[name]}%` : null}
      {uploadProgress[name] === 100 ? 'Done' : null}
    </span>
  );
};
