import React, { useEffect } from 'react';
import { Typography } from '@repo/mui/Typography';
import { Stack } from '@repo/mui/Stack';
import { blueGrey } from '@repo/mui/colors';
import { useTranslation } from '@repo/translation/react-i18next';
import { LoginForm } from './LoginForm';
import { LogoSVG } from '@repo/components';
import { useSearchParams } from '@repo/routing';
import { logout } from '../../logout.ts';
import { Alert } from '@repo/mui/Alert';
import { LanguageSelect } from '../../components/LanguageSelect';

export const LoginPage = () => {
  const { t } = useTranslation('tabs');
  const searchParams = useSearchParams();

  useEffect(() => {
    logout();
  }, []);

  return (
    <Stack>
      <Stack
        justifyContent='center'
        alignItems='center'
        sx={{
          color: blueGrey[900],
          padding: 2,
          boxShadow: `0 -10px 30px 0 ${blueGrey[400] + '55'}`,
        }}>
        <div style={{ marginBottom: 16 }}>
          <LogoSVG size={56} />
        </div>
        <Typography
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
          fontSize='1.2rem'>
          Roger's Snow Removal <br />& Landscaping
        </Typography>
        <Typography variant='caption'>Worker App</Typography>
        <LanguageSelect saveLanguageToRemote={false} />
      </Stack>
      {searchParams.get('reason') === '401' && (
        <Alert severity='warning'>{t('expiredToken')}</Alert>
      )}
      <LoginForm />
    </Stack>
  );
};
