import { TFunction } from '@repo/translation/i18next';
import { FieldError } from '@repo/form/rhf';

export const getPhoneError = (fieldError: FieldError | undefined, t: TFunction) => {
  if (!fieldError) return null;

  const { type } = fieldError;
  if (type === 'required') {
    return t('phoneInputErrorMessages.required', { ns: 'onboardingForm' });
  }
  if (type === 'maxLength' || type === 'minLength') {
    return t('phoneInputErrorMessages.length', { ns: 'onboardingForm' });
  }
  if (type === 'pattern') {
    return t('phoneInputErrorMessages.pattern', { ns: 'onboardingForm' });
  }
  return undefined;
};
