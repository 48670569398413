import { Typography } from '@repo/mui/Typography';
import { Box } from '@repo/mui/Box';
import { grey } from '@repo/mui/colors';
import { ImageList } from '../ImageList';
import UploadArea from '../UploadArea';

interface Props {
  urls: string[];
  onUploadDone: (urls: string[]) => void;
  onPictureDoubleClick?: (url: string) => void;
  id: string;
  enableDelete?: boolean;
  captions?: string[];
  containerStyle?: React.CSSProperties;
  error?: boolean;
}

export const ImageUploadList: React.FC<Props> = (props) => {
  const { id, urls, onPictureDoubleClick, captions, enableDelete, containerStyle, onUploadDone } =
    props;

  return (
    <div
      style={{
        ...(props.error && { border: '1px solid red', borderRadius: '4px' }),
        ...containerStyle,
      }}>
      <UploadArea id={id} onUploadDone={onUploadDone} />
      {urls.length > 0 && (
        <Box
          sx={{
            background: grey[200],
            borderRadius: '4px',
            boxShadow: `inset 0 0 0 1px ${grey[300]}`,
            padding: '16px 4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2px',
          }}>
          <Typography variant='overline'>Uploaded Images</Typography>
          <ImageList
            id={id}
            urls={urls}
            onDoubleClick={onPictureDoubleClick}
            captions={captions}
            enableDelete={enableDelete}
          />
        </Box>
      )}
    </div>
  );
};
