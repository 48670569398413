import { TextField, TextFieldProps } from '@repo/mui/TextField';
import { Ref, useCallback, useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import some from 'lodash.some';

export type GooglePlace = google.maps.places.PlaceResult;
export type Coords = {
  longitude: number;
  latitude: number;
};

type Props = {
  label?: string;
  value?: string;
  onChange?: (val: string) => void;
  errorText?: string;
  setFormValue: (stateKey: any, value: any) => void;
  getDistance?: (miles: number) => void;
  required?: boolean;
  formRegister?: any;
  getCoords?: (coords: Coords | undefined) => void;
  hideLabel?: boolean;
  translationFn?: (item: string) => string;
  disableLoadApi?: boolean;
  apiKey?: string;
} & Omit<TextFieldProps, 'onChange'>;

const BOSTON = {
  center: {
    lat: 42.3601,
    lng: -71.0589,
  },
};

export const GoogleAddressInput = ({
  onChange,
  errorText,
  value,
  setFormValue,
  label,
  hideLabel,
  getDistance,
  getCoords,
  formRegister,
  translationFn,
  required = true,
  apiKey,
  ...rest
}: Props) => {
  const [selectedAddress, setSelectedAddress] = useState<GooglePlace>();
  const [validationErrorMessage, setValidationErrorMessage] = useState<string>('');

  useEffect(() => {
    setValidationErrorMessage(errorText as string);
  }, [errorText]);

  const calcAddressDistance = useCallback(() => {
    if (selectedAddress?.formatted_address) {
      const requestDistance = {
        origins: [BOSTON.center],
        destinations: [selectedAddress.formatted_address],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      };
      const service = new google.maps.DistanceMatrixService();
      const parseMiles = (t: string) => Number(t?.split(' ')[0]?.replace(',', '.')) || 0;
      service.getDistanceMatrix(requestDistance).then((response: any) => {
        const miles = parseMiles(response.rows[0]!.elements[0]!.distance?.text);

        // if parent requests distance, invoke callback to send miles info to calling parent
        if (getDistance) getDistance(miles);
      });
    }
  }, [selectedAddress?.formatted_address]);

  useEffect(() => {
    if (window.google && selectedAddress) calcAddressDistance();
  }, [selectedAddress]);

  const onAddressSelect = (place: GooglePlace) => {
    // set the address state (user can change, so we will validate against selectedAddressObj when submitting)
    setFormValue(label, place.formatted_address);

    if (!some(place?.address_components, { types: ['postal_code'] })) {
      setValidationErrorMessage(
        translationFn
          ? translationFn('addressInputErrorPostal')
          : 'Please enter your full address with postal code',
      );
      return;
    }

    setValidationErrorMessage('');
    // set the original validated address for validation when submitting the form
    setSelectedAddress(place);

    if (getCoords) {
      getCoords({
        latitude: place.geometry?.location?.lat() as number,
        longitude: place.geometry?.location?.lng() as number,
      });
    }
  };

  const { ref } = usePlacesWidget<Ref<HTMLInputElement>>({
    apiKey: apiKey,
    onPlaceSelected: (place) => {
      setSelectedAddress(place);
      onAddressSelect(place);
    },
    libraries: ['places'],
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      fields: ['formatted_address', 'url', 'geometry', 'address_components'],
    },
  });

  const restoreValidatedAddress = () => {
    // if user accidentally changes the validated address, here we will restore it from the validated address
    // ex. user selects an address from google suggestion, then, deletes someting and blurs from the input
    // this will cause the address in form state to be diverged from the validated address
    if (selectedAddress?.formatted_address !== value) {
      setFormValue('address', selectedAddress?.formatted_address);
    }
  };

  const cleanSelectedAddress = () => {
    // If user selected an address from google suggestions then removed the address from input,
    // we were keeping the coords and selected address as before.
    // So to prevent this issue, I made this update to remove the coords and revalidate the address
    if (!value || value !== selectedAddress?.formatted_address) {
      setSelectedAddress(undefined);
      setValidationErrorMessage(
        translationFn
          ? translationFn('addressInputErrorSuggested')
          : 'Please select your suggested address from the google dropdown. Do not use your saved addresses',
      );

      if (getCoords) {
        getCoords(undefined);
      }
    }
  };

  useEffect(cleanSelectedAddress, [value, cleanSelectedAddress]);

  return (
    <TextField
      required={required}
      inputRef={ref}
      autoFocus={false}
      label={hideLabel ? '' : label}
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      onBlur={restoreValidatedAddress}
      helperText={validationErrorMessage ? validationErrorMessage : ''}
      error={!!validationErrorMessage}
      value={value || ''}
      InputLabelProps={{ shrink: true }}
      {...formRegister}
      {...(rest as TextFieldProps)}
    />
  );
};
