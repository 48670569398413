import { v4 } from 'uuid';

export const apiRoot = process.env['NEXT_PUBLIC_API_ROOT'] || process.env['NX_API_ROOT'];

export function getUrl(pathname: string) {
  const API_ROOT = apiRoot || '';
  return API_ROOT + pathname;
}

export const abortController = new AbortController();
const { signal } = abortController;

class UiError extends Error {
  public override message = '';
  public requestId = '';

  public override toString() {
    console.error(`${this.message} -- RequestID: ${this.requestId}`);
    return `${this.message}`;
  }
}

const validateRes = async (res: Response, requestId: string) => {
  if (res.ok) return;
  if (res.status === 404) return;
  // TODO(ege): This validateRes function blocks us from sharing API messages. We need to refactor. This is a work around until we refactor this function and how error messages handled on UI. Basically, if I didn't have this bypass for 404, UI would throw and Error Boundry layout and break the whole app.

  if (res.status === 401 && window.location.pathname !== '/login') {
    window.location.replace('/login?reason=401');
    return;
  }

  const { message, details } = await res.json();

  if (details) {
    throw new Error(`${details.publicErrorMessage || ''} - ErrorID: ${details.errorID || ''}`);
  }

  if (!message) {
    throw new Error(`Missing 'message' field from the response.`);
  }

  const uiError = new UiError();
  uiError.message = message;
  uiError.requestId = requestId;
  throw uiError;
};

export async function getRequest<T>(pathname: string, token = ''): Promise<T | undefined> {
  if (signal.aborted) return;

  const requestId = v4();
  const res = await fetch(getUrl(pathname), {
    headers: {
      token, // todo: remove after nest refactor
      Authorization: 'Bearer ' + token,
      requestId,
    },
    signal,
  });

  await validateRes(res, requestId);

  return res.text().then(function (text) {
    return text ? JSON.parse(text) : {};
  });

  // return res.json();
}

export async function getRequestV2<T>({
  pathname,
  token = '',
}: {
  pathname: string;
  token?: string;
}): Promise<T | undefined> {
  return getRequest<T>(pathname, token);
}

export async function postRequest<T>(
  pathname: string,
  body: Record<string, any>,
  token = '',
): Promise<T | undefined> {
  if (signal.aborted) return;

  const requestId = v4();

  const headers = {
    'X-Request-Timestamp': new Date().toString(),
    'Content-type': 'application/json; charset=UTF-8',
    token, // todo: remove after nest refactor
    Authorization: 'Bearer ' + token,
    requestId,
  };
  const res = await fetch(getUrl(pathname), {
    method: 'POST',
    body: JSON.stringify(body || {}),
    headers,
    signal,
  });
  await validateRes(res, requestId);

  return res.text().then(function (text) {
    return text ? JSON.parse(text) : {};
  });
}

export async function postRequestV2<T>({
  pathname,
  body = {},
  token = '',
}: {
  pathname: string;
  body?: any;
  token?: string;
}): Promise<T | undefined> {
  return postRequest<T>(pathname, body, token);
}

export async function patchRequest<T>(
  pathname: string,
  body = {},
  token = '',
): Promise<T | undefined> {
  if (signal.aborted) return;

  const requestId = v4();
  const res = await fetch(getUrl(pathname), {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      token, // todo: remove after nest refactor
      Authorization: 'Bearer ' + token,
    },
    signal,
  });

  await validateRes(res, requestId);

  return res.json();
}

export async function patchRequestV2<T>({
  pathname,
  body = {},
  token = '',
}: {
  pathname: string;
  body?: any;
  token?: string;
}): Promise<T | undefined> {
  return patchRequest<T>(pathname, body, token);
}

export async function putRequest<T>(
  pathname: string,
  body = {},
  token = '',
): Promise<T | undefined> {
  if (signal.aborted) return;

  const requestId = v4();
  const res = await fetch(getUrl(pathname), {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      token, // todo: remove after nest refactor
      Authorization: 'Bearer ' + token,
    },
    signal,
  });

  await validateRes(res, requestId);
  return res.json();
}

export async function deleteRequest(token = '', pathname: string, body = {}) {
  if (signal.aborted) return;

  const requestId = v4();
  const res = await fetch(getUrl(pathname), {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      token, // todo: remove after nest refactor
      Authorization: 'Bearer ' + token,
    },
    signal,
  });

  await validateRes(res, requestId);
  return res.json();
}

export async function deleteRequestV2({
  token = '',
  pathname,
  body = {},
}: {
  token: string;
  pathname: string;
  body?: any;
}) {
  return deleteRequest(token, pathname, body);
}
