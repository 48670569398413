import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@repo/mui/Dialog';
import { Button } from '@repo/mui/Button';
import { memo } from 'react';

type Props = {
  open: boolean;
  id?: string;
  setUserConfirmedDelete: (value: 'yes' | 'no' | '') => void;
};

function ConfirmationDialog({ id, open, setUserConfirmedDelete }: Props) {
  return (
    <Dialog
      id={`${id}-delete-confirmation`}
      open={open}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'>
      <DialogTitle id='dialog-title'>Delete?</DialogTitle>
      <DialogContent>
        <DialogContentText id='dialog-description'>
          Do you want to delete this picture?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={() => setUserConfirmedDelete('no')}>
          Cancel
        </Button>
        <Button variant='contained' color='error' onClick={() => setUserConfirmedDelete('yes')}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// Memoize this component because it should never need a re-render
export const ImageDeleteConfirmationDialog = memo(ConfirmationDialog);
