import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight, Close } from '@repo/mui/icons';
import { Dialog } from '@repo/mui/Dialog';
import { DialogContent } from '@repo/mui/Dialog';
import { IconButton } from '@repo/mui/IconButton';
import { Skeleton } from '@repo/mui/Skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { grey } from '@repo/mui/colors';

const buttonColor = {
  text: 'white',
  background: grey[800],
  hoverBackground: grey[700],
};
const navButtonStyle = {
  background: buttonColor.background,
  color: buttonColor.text,
  position: 'absolute',
  top: 'calc(50% - 8px)',
  '&:hover': {
    background: buttonColor.hoverBackground,
  },
};
const closeButtonStyle = {
  position: 'absolute',
  top: 8,
  right: 8,
  background: buttonColor.background,
  color: buttonColor.text,
  '&:hover': {
    background: buttonColor.hoverBackground,
  },
};

type Props = {
  openAt: number | null;
  urls: string[];
  onClose: () => void;
  id?: string;
};

export function ZoomModal({ openAt, urls, onClose }: Props) {
  const [picNum, setPicNum] = useState<number | null>(openAt);
  const nextPossible = picNum === null ? false : picNum < urls.length - 1;
  const next = () => setPicNum((p) => (p === null ? null : nextPossible ? p + 1 : p));
  const nextButton = (
    <IconButton hidden={!nextPossible} sx={{ ...navButtonStyle, right: 8 }} onClick={next}>
      <ChevronRight />
    </IconButton>
  );

  const prevPossible = picNum === null ? false : picNum > 0;
  const prev = () => setPicNum((p) => (p === null ? null : prevPossible ? p - 1 : p));
  const prevButton = (
    <IconButton hidden={!prevPossible} sx={{ ...navButtonStyle, left: 8 }} onClick={prev}>
      <ChevronLeft />
    </IconButton>
  );

  useEffect(() => {
    setPicNum(openAt);
  }, [openAt]);

  if (!urls.length) return null;

  return (
    <Dialog open={picNum !== null ? true : false} onClose={onClose} sx={{ padding: 0 }}>
      <IconButton onClick={onClose} sx={closeButtonStyle}>
        <Close />
      </IconButton>
      <DialogContent
        style={{
          background: 'black',
          minWidth: 300,
          minHeight: 200,
        }}>
        {prevButton}
        {openAt !== null && picNum !== null ? (
          <LazyLoadImage
            src={urls[picNum]}
            key={`image-element-${urls[picNum]}-in-modal`}
            width='100%'
            height='100%'
            alt='image list item'
            placeholder={<Skeleton variant='rectangular' width='400' height='360' />}
            loading='eager'
          />
        ) : null}
        {nextButton}
      </DialogContent>
    </Dialog>
  );
}
