import { Fragment, useCallback, useEffect, useState } from 'react';
import { ZoomIn } from '@repo/mui/icons';
import { Close } from '@repo/mui/icons';
import { Fade } from '@repo/mui/Fade';
import { Box } from '@repo/mui/Box';
import { Skeleton } from '@repo/mui/Skeleton';
import { IconButton } from '@repo/mui/IconButton';
import { Typography } from '@repo/mui/Typography';
import { useTheme } from '@repo/mui/theme';
import { useMediaQuery } from '@repo/mui/media-query';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageDeleteConfirmationDialog } from './ImageDeleteConfirmationDialog';
import { ZoomModal } from './ZoomModal';

interface Props {
  id?: string;
  urls: string[];
  onDoubleClick?: (url: string) => void;
  captions?: string[];
  enableDelete?: boolean;
}

export const ImageList: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { urls = [], onDoubleClick = () => null, captions, enableDelete } = props;
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  // states
  const [pictureURLToDelete, setPictureURLToDelete] = useState('');
  const [userConfirmedDelete, setUserConfirmedDelete] = useState<'no' | 'yes' | ''>('');
  const [zoomModalOpen, setZoomModalOpen] = useState<number | null>(null);
  const [hover, setHover] = useState('');

  // helper functions
  const confirmDelete = (url: string) => () => {
    if (enableDelete) {
      setPictureURLToDelete(url);
    }
  };
  const deletePicture = () => {
    if (userConfirmedDelete === 'yes') onDoubleClick(pictureURLToDelete);
    setPictureURLToDelete('');
    setUserConfirmedDelete('');
  };
  const openZoomModal = (picNum: number) => setZoomModalOpen(picNum);
  const closeZoomModal = () => setZoomModalOpen(null);

  const renderActionOverlay = useCallback(
    (hovered: boolean, url: string, i: number) => (
      <Fade in={hovered} unmountOnExit key={`action-overlay-${url}`}>
        <div>
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: '#0008',
              zIndex: 10,
              top: 0,
              display: 'grid',
              placeContent: 'center',
            }}>
            <IconButton sx={{ color: 'white' }} size='small' onClick={() => openZoomModal(i)}>
              <ZoomIn sx={{ fontSize: '2rem' }} />
            </IconButton>
            {enableDelete && (
              <IconButton
                onClick={confirmDelete(url)}
                size='small'
                sx={{
                  position: 'absolute',
                  top: '2px',
                  right: '2px',
                  color: 'white',
                }}>
                <Close sx={{ fontSize: '1.4rem', fontWeight: 900 }} />
              </IconButton>
            )}
          </Box>
        </div>
      </Fade>
    ),
    [enableDelete],
  );

  // side effects
  useEffect(deletePicture, [userConfirmedDelete]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          overflowY: 'scroll',
          overflowX: 'hidden',
          width: '100%',
          justifyContent: 'center',
        }}>
        {urls.map((url, i) => {
          const isHovered = hover === url;
          return (
            <Fragment key={url}>
              <Box
                key={`image-container-${url}`}
                sx={{
                  position: 'relative',
                  display: 'inline-block',
                  lineHeight: 0,
                  borderRadius: '4px',
                  overflow: 'hidden',
                  margin: '4px',
                  width: largeScreen ? '10vw' : '38vw',
                  height: largeScreen ? '10vw' : '38vw',
                }}
                onMouseEnter={() => setHover(url)}
                onMouseLeave={() => setHover('')}
                onDoubleClick={confirmDelete(url)}>
                <LazyLoadImage
                  visibleByDefault
                  src={url}
                  key={`image-element-${url}`}
                  width='100%'
                  height='100%'
                  alt='image list item'
                  placeholder={<Skeleton variant='rectangular' width='20vw' height='20vw' />}
                  style={{
                    objectFit: 'cover',
                    maxWidth: '400px',
                  }}
                />
                {renderActionOverlay(isHovered, url, i)}
              </Box>
              {captions && (
                <Typography key={`caption-${url}`} variant='caption'>
                  {captions[i]}
                </Typography>
              )}
            </Fragment>
          );
        })}
      </Box>

      <ZoomModal openAt={zoomModalOpen} urls={urls} onClose={closeZoomModal} />

      <ImageDeleteConfirmationDialog
        id={props.id}
        open={!!pictureURLToDelete}
        setUserConfirmedDelete={setUserConfirmedDelete}
      />
    </>
  );
};
