import { CircularProgress } from '@repo/mui/CircularProgress';
import { Typography } from '@repo/mui/Typography';

type Props = {
  text?: string;
};
export function Loading({ text }: Props) {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      {text ? (
        <Typography variant='overline' color='secondary' fontSize='1rem'>
          {text}
        </Typography>
      ) : null}
      <br />
      <CircularProgress />
    </div>
  );
}
