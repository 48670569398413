export const formatPhoneNumber = (previousValue: string) => {
  if (!previousValue) return '';
  const value = previousValue.replace(/[^\d]/g, '');
  if (value.length < 4) return value;
  if (value.length < 7) return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
};

export const PHONE_REGISTER_OPTIONS = Object.freeze({
  required: true,
  minLength: 10,
  pattern: /^[0-9]+$/i,
});

/**
 * trimAndCapitalize function is for formatting the values before sending to db
 * @param obj is obj that we want to send to db
 * @param keys are the keys whose values we want to trim or capitalize
 */
export const trimAndCapitalize = <T>(obj: T, keys: (keyof T)[]) => {
  let formattedObj = obj;

  keys.forEach((key) => {
    const value = obj[key];
    const isStringArray =
      Array.isArray(value) && value.every((i) => typeof i === 'string') && value.length !== 0;

    let newValue;
    switch (key) {
      case 'name':
      case 'fullname':
        if (typeof value === 'string') {
          newValue = value
            .trim()
            .split(' ')
            .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ');
        }
        break;
      case 'email':
        if (typeof value === 'string') {
          newValue = value.trim().toLowerCase();
        }
        break;
      case 'languages':
        if (isStringArray) {
          newValue = value.map((lang) => lang.trim());
        }
        break;
      default:
        if (typeof value === 'string') {
          newValue = capitalizeFirstLetter(value.trim());
        } else {
          newValue = value;
        }
    }

    formattedObj = { ...formattedObj, [key]: newValue };
  });

  return formattedObj;
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * normalizeSnakeCase function is for normalizing the values
 * @param value is that we want to normalize and capitalize
 */
export const normalizeSnakeCase = (value: string) =>
  value
    .split('_')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
