import { uploadImage } from '@repo/shared-utils/imageUploadApi';
import { useMutation } from '@repo/tanstack-query';

export type UploadItem = {
  type: string;
  name: string;
  size: string;
  base64: string;
};

export const useUploadFileList = () => {
  const { mutateAsync, ...rest } = useMutation({
    mutationFn: async (filesToUpload: UploadItem[]) => {
      const uploadedImageUrls: string[] = [];
      for (const file of filesToUpload) {
        try {
          const response = await uploadImage({
            type: file.type,
            base64: file.base64,
            fileName: file.name,
          });
          if (response) {
            uploadedImageUrls.push(response.url);
          } else {
            console.warn(
              `Could not upload image file: "${file.name}". Got empty response from server.`,
            );
          }
        } catch (err) {
          console.warn(`An error occured while uploading image file: "${file.name}"`);
        }
      }
      return uploadedImageUrls;
    },
  });

  return {
    uploadFileList: mutateAsync,
    ...rest,
  };
};
